<template>
  <LxpMobileHeader v-if="isMobile" :use-route-name="true">
    <template v-slot:right>
      <div v-if="view === 'certification'" class="header-column">
        <div v-if="guideFile" class="util util-download">
          <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-mypage" @click="effected++">
    <lxp-main-header v-if="!isMobile" :show-breadcrumb="true">
      <template v-slot:inner>
        <div v-if="view === 'history'" class="header-column">
          <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg"  @click="historyPrintModal = true">
            <i class="icon-print"></i>
            <span class="text">연수이력인쇄</span>
          </button>
        </div>
        <div v-else-if="view === 'certification'" class="header-column">
          <file-down-link :file-key="guideFile" />
        </div>
      </template>
    </lxp-main-header>
    <div class="main-content min-component">
      <div class="mypage-header">
        <MyPageProfile/>
        <MyPageRoute
            :view="view"
            @changeView="changeView"
        />
      </div>
      <div class="mypage-body">
        <MyPageDashboard
            v-if="!$route.query.view || $route.query.view === 'dashboard'"
            :learns="learns"
            :licenses="licenses"
            :badges="badges"
            @changeView="changeView"
        />
        <MyPageHistory
            v-else-if="$route.query.view === 'history'"
            :learns="learns"
            :licenses="licenses"
            :globals="globals"
            :effected="effected"
            :banks="banks"
            :evaluations="evaluations"
            :refund="refund"
        />
        <MyPageMileage
            v-else-if="$route.query.view === 'mileage'"
            :learns="mlgLearns"
            :licenses="mlgLicenses"
            :hrdCloudMlg="hrdCloudMlg"
        />
        <MyPageCertification
            v-else-if="$route.query.view === 'certification'"
            :works="works"
            :learns="learns"
        />
      </div>
    </div>
  </main>
  <MypageMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :file-key="guideFile"/>
  <MyPageHistoryPrint
      v-if="historyPrintModal"
      v-model="historyPrintModal"
      :learns="learns"
      :licenses="licenses"
  />
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import MyPageProfile from '@/components/mypage/MyPageProfile';
import MyPageRoute from '@/components/mypage/MyPageRoute';
import MyPageDashboard from '@/components/mypage/MyPageDashboard';
import MyPageHistory from '@/components/mypage/MyPageHistory';
import MyPageMileage from '@/components/mypage/MyPageMileage';
import MyPageCertification from '@/components/mypage/MyPageCertification';
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MypageMobileFileAtch from "@/views/pages/my/mobile/MyPageMobileFileAtch";
import MyPageHistoryPrint from '@/components/mypage/MyPageHistoryPrint';
import FileDownLink from "@/components/common/FileDownLink";
import {mypageSetup} from "@/assets/js/modules/mypage/mypage-setup";

export default {
  name: 'MyPageMain',
  components: {
    MypageMobileFileAtch,
    LxpMobileHeader,
    MyPageCertification,
    MyPageMileage,
    MyPageHistory,
    MyPageHistoryPrint,
    MyPageDashboard,
    MyPageRoute, MyPageProfile, LxpMainHeader,
    FileDownLink
  },
  setup: mypageSetup
};
</script>
