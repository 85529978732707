<template>
    <article class="content-section section-spacing">
      <header class="section-header">
<!--        <h4 class="title">연수비용환입 <span class="text-success" style="margin-left: 1em;"><i class="mdi mdi-information-outline"></i> 조회일 기준 ‘대기’인 과정은 과정 종료 후 다음 분기 급여일에 맞춰 환입이 진행되며, ‘진행중’인 과정은 익월 급여일자에 맞춰 출금됩니다.</span></h4>-->
<!--        <h4 class="title">자격증 특례인정 <span class="text-success" style="margin-left: 1em;"><i class="mdi mdi-information-outline"></i> 자격증 복수 보유시에도 1 개 과목 특례인정</span></h4>-->
        <h4 class="title">연수비용환입</h4>
        <span class="p-2 text-success"><i class="mdi mdi-information-outline"></i> 조회일 기준 ‘대기’인 과정은 과정 종료 후 다음 분기 급여일에 맞춰 환입이 진행되며, ‘진행중’인 과정은 익월 급여일자에 맞춰 출금됩니다.
</span>
      </header>
      <div v-if="isMobile" class="text-item-container">
        <div v-for="(item, idx) in items" class="text-item" :key="idx">
          <div class="item-row">
            <span class="text-title">과정명</span>
            <span class="text-description">{{ item.crseNm }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">환입금액</span>
            <span class="text-description">{{ item.rtnAmt.toLocaleString('en-US') }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">학습기간</span>
            <span class="text-description">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">비고</span>
            <span class="text-description">{{ item.cdNm }}</span>
          </div>
        </div>
        <div v-if="items.length === 0" class="search-container">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">조회일 기준 현재 환입 예정 금액은 없습니다.</p>
          </div>
        </div>
      </div>
      <div v-else class="kb-table kb-table-bordered-v2">
        <table>
          <colgroup>
            <col style="width:auto">
            <col style="width:200px">
            <col style="width:300px">
            <col style="width:150px">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">과정명</span></th>
            <th><span class="th-title">환입금액</span></th>
            <th><span class="th-title">학습기간</span></th>
            <th><span class="th-title">비고</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, idx) in items" :key="idx">
            <td><strong class="td-text">{{ item.crseNm }}</strong></td>
            <td><strong class="td-text">{{ item.rtnAmt.toLocaleString('en-US') }}원</strong></td>
            <td><strong class="td-text">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</strong></td>
            <td><strong class="td-text">{{ item.cdNm }}</strong></td>
          </tr>
          <tr v-if="items.length === 0">
            <td colspan="4"><div class="text-truncate text-center"><strong class="td-text">조회일 기준 현재 환입 예정 금액은 없습니다.</strong></div></td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
</template>

<script>
import navigationUtils from "@/assets/js/navigationUtils";
import {timestampToDateFormat} from "../../../assets/js/util";

export default {
  name: "MyPageRefund",
  methods: {timestampToDateFormat},
  props: {
    items: Array
  },
  setup() {
    return {
      isMobile: navigationUtils.any()
    }
  }
}
</script>